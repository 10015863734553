<template>
  <div class="auth-loading">
    <ChecLoading v-if="!error" />

    <ChecAlert
      v-if="error"
      variant="error"
      @close="handleCloseError"
    >
      {{ error }}
    </ChecAlert>
  </div>
</template>

<script>
import { ChecAlert, ChecLoading } from '@chec/ui-library';
import Client from '@/lib/auth/client';
import pkceParams, { clearState } from '@/lib/auth/pkceParams';
import authState from '@/lib/auth/state';

export default {
  name: 'AuthCallbackView',
  components: {
    ChecAlert,
    ChecLoading,
  },
  data() {
    return {
      error: '',
    };
  },
  mounted() {
    this.getToken();
  },
  methods: {
    /**
     * Where should we return the user to on success?
     * @returns {object} An object that matches the Route pattern for Vue
     */
    returnTo() {
      return {
        name: window.localStorage.getItem('return_to') || 'home',
        params: JSON.parse(window.localStorage.getItem('return_to_params')) || {},
      };
    },
    /**
     * Attempt to clear local storage for the return route
     */
    clearReturnTo() {
      window.localStorage.removeItem('return_to');
      window.localStorage.removeItem('return_to_params');
    },
    /**
     * Fetches an authorization code JWT token using the routed code and state, and verifier
     * from session storage. See router/index.js for initialisation logic.
     */
    async getToken() {
      if (authState.isTokenValid()) {
        // Already logged in
        this.$router.push(this.returnTo());
        return;
      }

      const authCodePkceParams = pkceParams();
      let user;

      try {
        user = await Client.code.getToken(
          `${Client.options.redirectUri}?state=${authCodePkceParams.state}&code=${this.$route.query.code}`,
          {
            body: { code_verifier: authCodePkceParams.code_verifier },
          },
        );
      } catch (error) {
        this.error = this.$t('auth.somethingWentWrong');
        authState.clear();
        return;
      }

      if (user && user.accessToken) {
        this.error = '';
        authState.setOAuthUser(user);
        clearState();
        this.$router.push(this.returnTo());
        this.clearReturnTo();
        return;
      }

      this.error = this.$t('auth.unableToAuth');
    },
    handleCloseError() {
      this.$router.push(this.returnTo());
    },
  },
};
</script>
